<template>
  <div>
    <heard-and-footer v-on:getTitleName="getTitleName">
      <title-name slot="title" :my-name="title"
                  :my-background="require('../../assets/image/zhuanjia.png')"></title-name>
      <div slot="content">
        <second-navi-bar v-on:getTitle="getTitleName" v-on:setTitle="setTitle" :nav="nav" :now-page="nowPage" :parent1="parent1"></second-navi-bar>
        <router-view v-on:getTitle="getTitle"></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
import SecondNaviBar from "@/components/public/SecondNaviBar";

export default {
  name: "expert",
  components: {
    heardAndFooter,
    TitleName,
    SecondNaviBar,
  },
  data() {
    return {
      title: this.$route.query.name,
      nowPage:this.$route.query.name,
      parent1:'',
      nav: {
        parent: '智库专家',
        children: [
          {index: 0, name: '专家风采', to: '/mien?name=专家风采'},
          {index: 1, name: '专家文献', to: '/literature?name=专家文献'},
          {index: 2, name: '行业报告', to: '/report?name=行业报告'},
        ]
      }
    }
  },
  methods: {
    getTitleName(val) {
      this.title = val
      this.nowPage = val
    },
    getTitle(val){
      this.parent1=this.nowPage
      this.nowPage=val
    },
    setTitle(val){
      this.nowPage=val.nowPage
      this.parent1=val.parent1
    }
  }
}
</script>

<style scoped>

</style>